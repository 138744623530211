//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-440:_664,_940,_5644,_3988,_1008,_5640,_4336,_2400;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-440')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-440', "_664,_940,_5644,_3988,_1008,_5640,_4336,_2400");
        }
      }catch (ex) {
        console.error(ex);
      }